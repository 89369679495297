import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
Vue.use(VueRouter);

// Global
const HomePage = () => import(/* webpackChunkName: "global" */ '@/pages/global/home.vue');
const ContentPage = () => import(/* webpackChunkName: "global" */ '@/pages/global/content.vue');

// Auth
const AuthPage = () => import(/* webpackChunkName: "authentication" */ '@/pages/auth/auth.vue');
const LoginPage = () => import(/* webpackChunkName: "authentication" */ '@/pages/auth/login.vue');
const LoginSendPage = () => import(/* webpackChunkName: "authentication" */ '@/pages/auth/login-send.vue');
const ProfilePage = () => import(/* webpackChunkName: "authentication" */ '@/pages/auth/profile.vue');
const CompleteAccountPage = () => import(/* webpackChunkName: "authentication" */ '@/pages/auth/complete.vue');
const MyDataPage = () => import(/* webpackChunkName: "authentication" */ '@/pages/auth/my-data.vue');
const AdminPage = () => import(/* webpackChunkName: "authentication" */ '@/pages/auth/admin.vue');

// Charity
const CharityOverviewPage = () => import(/* webpackChunkName: "charity" */ '@/pages/charity/charities-overview.vue');
const CharityDetailPage = () => import(/* webpackChunkName: "charity" */ '@/pages/charity/charity-detail.vue');

// Payment
const ConfirmPage = () => import(/* webpackChunkName: "payment" */ '@/pages/payment/confirm.vue');
const IDealPage = () => import(/* webpackChunkName: "ideal" */ '@/pages/payment/ideal.vue');
const ThankYouPage = () => import(/* webpackChunkName: "payment" */ '@/pages/payment/thank-you.vue');

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
  },
  {
    path: '/inloggen',
    name: 'LoginPage',
    component: LoginPage,
    props: true,
  },
  {
    path: '/inloggen-verstuurd',
    name: 'LoginSendPage',
    component: LoginSendPage,
    props: true,
  },
  {
    path: '/auth',
    name: 'AuthPage',
    component: AuthPage,
  },
  {
    path: '/goede-doelen',
    name: 'CharityOverviewPage',
    component: CharityOverviewPage,
  },
  {
    path: '/goede-doelen/:id',
    name: 'CharityDetailPage',
    component: CharityDetailPage,
  },
  {
    path: '/profiel',
    name: 'ProfilePage',
    component: ProfilePage,
  },
  {
    path: '/admin',
    name: 'AdminPage',
    component: AdminPage,
  },
  {
    path: '/profiel/maak-compleet',
    name: 'CompleteAccountPage',
    component: CompleteAccountPage,
  },
  {
    path: '/profiel/mijn-gegevens',
    name: 'MyDataPage',
    component: MyDataPage,
  },
  {
    path: '/bevestigen',
    name: 'ConfirmPage',
    component: ConfirmPage,
  },
  {
    path: '/ideal',
    name: 'IDealPage',
    component: IDealPage,
  },
  {
    path: '/dank-je-wel',
    name: 'ThankYouPage',
    component: ThankYouPage,
  },
  {
    path: '/:slug',
    name: 'ContentPage',
    component: ContentPage,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // @ts-ignore
  scrollBehavior() {
    return { y: 0 };
  },
});

export default router;
