import { addYears, format, isValid, parse } from 'date-fns';
import { nl } from 'date-fns/locale';

function getDateFromString(date: string): Date | null {
  let result = null;
  const formatStrings = ['yyyy-MM-dd', 'dd-MM-yyyy'];

  for (let i = 0; i < formatStrings.length; i++) {
    result = parse(date, formatStrings[i], new Date(), { locale: nl });
    if (isValid(result)) {
      break;
    }
  }

  return result;
}

export function isDobValid(date: string | Date | null) {
  if (date === null) {
    return false;
  }

  const result = typeof date === 'string' ? getDateFromString(date) : date;
  if (!result) {
    return false;
  }

  const legalAge = 13;
  const maxDate = addYears(new Date(), legalAge * -1);

  return isValid(result) && result < maxDate;
}

export function formatDate(date: string | Date | null, formatStr: string) {
  if (date === null) {
    return null;
  }

  const result = typeof date === 'string' ? getDateFromString(date) : date;
  if (!result || !isValid(result)) {
    return null;
  }

  return format(result, formatStr);
}
