import firebase from 'firebase';
import User = firebase.User;

export interface ProviderData {
  firstName: string;
  lastName: string;
}

export interface Charity {
  id: string;
  name: string;
  amount?: number;
  shares: number;
}

export interface Subscription {
  amount: number;
  charities: Charity[];
  donatedOn?: string;
}

export interface Diff {
  additions: Charity[];
  removals: Charity[];
  hasChanges: boolean;
}

export interface Profile {
  firstName: string | null;
  lastName: string | null;
  dob: string | null;
  nationality: string | null;
}

export interface TempShares {
  [index: string]: number;
}

export interface TempConfirmationState {
  amount: number;
  tempShares: TempShares | null;
}

export interface State {
  user: User | null;
  profile: Profile | null;
  initiated: boolean;
  providerData: ProviderData | null;
  currentCharities: Charity[] | null;
  newCharities: Charity[];
  currentAmount: number;
  subscriptionActions: SubscriptionAction[];
  diff: Diff;
  tempConfirmationState: TempConfirmationState | null;
  nextPaymentDate: string | null;
  subscriptionStatus: string | null;
  idealClientSecret: string | null;
}

export enum ModifierTypes {
  add = 'add',
  remove = 'remove',
}

export interface SubscriptionAction {
  id: string;
  name: string;
  type: ModifierTypes;
  date: Date;
}
