













import { Component, Vue } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';
import { observeUser } from '@/firebase-app';
import firebase from 'firebase';
import User = firebase.User;
import SocialHeader from '@/components/nav/social-header.vue';
import SocialFooter from '@/components/nav/social-footer.vue';
import SocialDonationBar from '@/components/social-donation-bar.vue';
@Component({
  components: { SocialDonationBar, SocialFooter, SocialHeader },
})
export default class App extends Vue {
  @Mutation private signIn!: (payload: User) => void;

  private user: User | null = null;

  private async created() {
    observeUser(async user => {
      this.user = user;
      if (user) {
        this.signIn(user);
      }
    });
  }
}
