









































import { Component, Vue } from 'vue-property-decorator';
import { observeUser } from '@/firebase-app';
import firebase from 'firebase';
import User = firebase.User;
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue';

@Component({
  components: {
    LottieAnimation,
  },
})
export default class SocialHeader extends Vue {
  private user: User | null = null;

  private created() {
    observeUser(user => {
      this.user = user;
    });
  }
}
