
















import { Component, Vue } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import { Charity, Diff } from '@/models/store';

@Component
export default class SocialDonationBar extends Vue {
  @State private newCharities!: Charity[];
  @Getter private diff!: Diff;

  private showOnPages = ['CharityOverviewPage','CharityDetailPage'];

  private get isActive() {
    return this.showOnPages.indexOf(this.$route.name + '') !== -1;
  }

  private get isDisabled() {
    return !this.diff.hasChanges;
  }

  private get copy() {
    return this.newCharities.length === 1 ? 'goed doel' : 'goede doelen';
  }
}
