import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { auth } from '@/./firebase-app';
import rg4js from 'raygun4js';
// @ts-ignore
import VueYoutube from 'vue-youtube';
// @ts-ignore
import vueVimeoPlayer from 'vue-vimeo-player';
import VueGtag from 'vue-gtag';
// @ts-ignore
import VueTippy from 'vue-tippy';

Vue.config.productionTip = false;
Vue.use(VueYoutube);
Vue.use(vueVimeoPlayer);
Vue.use(VueTippy);
Vue.use(
  VueGtag,
  {
    config: {
      id: 'G-ZX9N7S74PR',
      params: {
        anonymize_ip: true,
      },
    },
    appName: 'Soc1al',
    pageTrackerScreenviewEnabled: true,
  },
  router
);

// Setup Raygun
rg4js('apiKey', '4bz6QjCvaj2C6GfgXEKpQ');
rg4js('enableCrashReporting', true);

// @ts-ignore
if (process.env.NODE_ENV === 'development') {
  rg4js('options', {
    allowInsecureSubmissions: true,
    debugMode: false,
  });
}

const unsubscribe = auth.onAuthStateChanged(() => {
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app');
  unsubscribe();
});

declare global {
  interface Window {
    config: any;
  }
}
window.config = window.config || {};
