import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

// Set main language to Dutch
firebase.auth().languageCode = 'nl';

export const db = firebaseApp.firestore();
export const auth = firebaseApp.auth();

export const observeUser = (observer: (user: firebase.User | null) => void) =>
  auth.onAuthStateChanged(observer);

export function getUser() {
  return auth.currentUser;
}

export const signOut = async () => await auth.signOut();

export default firebaseApp;
