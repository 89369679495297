import firebase from 'firebase';
import User = firebase.User;
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import {
  Charity,
  Diff,
  ModifierTypes,
  Profile,
  State,
  SubscriptionAction,
  TempConfirmationState,
} from '@/models/store';
import { ApiCharity, ApiProfile } from '@/models/api';
import { event } from 'vue-gtag';
import { isDobValid } from '@/utils/date-utils';

Vue.use(Vuex);

const defaultAmount = 20;
const store = new Vuex.Store<State>({
  plugins: [
    createPersistedState({
      paths: ['subscriptionActions', 'tempConfirmationState'],
    }),
  ],
  state: {
    user: null,
    profile: {
      firstName: null,
      lastName: null,
      dob: null,
      nationality: null,
    },
    initiated: false,
    providerData: null,
    currentCharities: [],
    currentAmount: defaultAmount,
    subscriptionActions: [],
    newCharities: [],
    diff: {
      additions: [],
      removals: [],
      hasChanges: false,
    },
    tempConfirmationState: null,
    nextPaymentDate: null,
    subscriptionStatus: null,
    idealClientSecret: null,
  },
  getters: {
    user(state) {
      return state.user;
    },
    profile(state) {
      return state.profile;
    },
    providerData(state) {
      return state.user;
    },
    currentCharities(state) {
      return state.currentCharities;
    },
    currentAmount(state) {
      return state.currentAmount;
    },
    newCharities(state) {
      return state.newCharities;
    },
    diff(state) {
      return state.diff;
    },
    tempConfirmationState(state) {
      return state.tempConfirmationState;
    },
    nextPaymentDate(state) {
      return state.nextPaymentDate;
    },
    isProfileComplete(state) {
      if (!state.profile) {
        return false;
      }

      const dobValid = isDobValid(state.profile.dob);
      return Boolean(state.profile.firstName && state.profile.lastName && state.profile.nationality && dobValid);
    },
    isInitiated(state) {
      return state.initiated;
    },
    idealClientSecret(state) {
      return state.idealClientSecret;
    },
    subscriptionStatus(state) {
      return state.subscriptionStatus;
    },
  },
  mutations: {
    signIn(store, user: User) {
      store.user = user;
    },
    signOut(store) {
      store.user = null;
    },
    setProfile(store, data: Profile) {
      store.profile = data;
    },
    setCurrentCharities(store, data: Charity[]) {
      store.currentCharities = data;
    },
    setCurrentAmount(store, data: number) {
      store.currentAmount = data;
    },
    setNewCharities(store, data: Charity[]) {
      store.newCharities = data;
    },
    setDiff(store, data: Diff) {
      store.diff = data;
    },
    setTempConfirmationState(store, data: TempConfirmationState) {
      store.tempConfirmationState = data;
    },
    setNextPaymentDate(store, data: string) {
      store.nextPaymentDate = data;
    },
    setSubscriptionStatus(store, data: string) {
      store.subscriptionStatus = data;
    },
    addAction(store, action: SubscriptionAction) {
      store.subscriptionActions = [...store.subscriptionActions, action].sort((a, b) =>
        Date.parse(a.date + '') > Date.parse(b.date + '') ? 1 : -1
      );
    },
    clearSubscriptionActions(store) {
      store.subscriptionActions = [];
    },
    setInitiated(store) {
      store.initiated = true;
    },
    setIdealClientSecret(store, idealClientSecret) {
      store.idealClientSecret = idealClientSecret;
    },
  },
  actions: {
    handleProfileResponse(store, profileResponse: Partial<ApiProfile>) {
      const amount = profileResponse.donationAmount?.value
        ? parseInt(profileResponse.donationAmount?.value + '', 10)
        : defaultAmount;
      store.commit('setProfile', profileResponse.profile);
      store.commit('setCurrentCharities', profileResponse.charities);
      store.commit('setCurrentAmount', amount);
      store.commit('setNextPaymentDate', profileResponse.nextPaymentDate);
      store.commit('setSubscriptionStatus', profileResponse.subscriptionStatus);
    },
    toggleCharityInSubscription({ commit, getters }, charity: Partial<ApiCharity>) {
      const isSupportedNgo = getters.newCharities.find((c: Charity) => c.id === charity.id);
      event('toggle-support', {
        event_category: isSupportedNgo ? ModifierTypes.remove : ModifierTypes.add,
        event_label: 'value',
        value: charity.name || charity.id,
      });

      commit('addAction', {
        id: charity.id,
        name: charity.name,
        type: isSupportedNgo ? ModifierTypes.remove : ModifierTypes.add,
        date: new Date(),
      } as SubscriptionAction);
    },
    signOut({ commit }) {
      commit('signOut');
      commit('setCurrentCharities', []);
      commit('clearSubscriptionActions', []);
    },
  },
  modules: {},
});

function setNewCharitiesAndDiff() {
  const currentCharities = store.state.currentCharities || [];
  const newCharities = [...currentCharities];

  // Play actions to get the new charities
  store.state.subscriptionActions.forEach(action => {
    if (action.type === ModifierTypes.add) {
      newCharities.push({
        id: action.id,
        name: action.name,
        shares: 1,
      });
    }

    if (action.type === ModifierTypes.remove) {
      const obj = newCharities.find(c => c.id === action.id);
      if (obj) {
        newCharities.splice(newCharities.indexOf(obj), 1);
      }
    }
  });

  // Make unique
  const ids = Array.from(new Set(newCharities.map(c => c.id)));
  const uniqueCharities = ids.map(id => newCharities.find(c => c.id === id));

  // Commit
  store.commit('setNewCharities', uniqueCharities);

  // Calc the diff
  const additions = store.state.newCharities.filter(c => currentCharities.map(c => c.id).indexOf(c.id) === -1);
  const removals = currentCharities.filter(c => store.state.newCharities.map(c => c.id).indexOf(c.id) === -1);
  const diff: Diff = {
    additions,
    removals,
    hasChanges: additions.length > 0 || removals.length > 0,
  };

  store.commit('setDiff', diff);
}

store.watch(() => store.state.subscriptionActions, setNewCharitiesAndDiff, {
  immediate: true,
});

store.watch(() => store.state.currentCharities, setNewCharitiesAndDiff, {
  immediate: true,
});

export default store;
